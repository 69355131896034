import { useList } from "@refinedev/core";
import { ExportButton } from "@refinedev/antd";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { IMAGE_PATHS } from "../../../constants";
import { IOrganization, IRoute, IStop, IStudent } from "../../../interfaces";
import dayjs from "dayjs";
import { useState, useEffect, useRef } from "react";
import { processStops } from "../../../utils/processStops";
import autoTable from "jspdf-autotable";

interface ProcessedStops {
  origin: google.maps.LatLngLiteral | string;
  destination: google.maps.LatLngLiteral | string;
  waypoints: google.maps.DirectionsWaypoint[];
}

type Props = {
  route?: IRoute;
  driver?: any;
};

const safeValue = (value: any): string =>
  value != null ? value.toString() : "";

const calculateStopTimes = (
  startTime: string,
  directionsResult: google.maps.DirectionsResult,
  stops: IStop[]
): IStop[] => {
  const startTimeMoment = dayjs(startTime, "hh:mm A");
  let currentTime = startTimeMoment;

  // Crear una copia de los paradas para evitar mutar el array original
  const updatedStops = [...stops];

  // Establecer el tiempo de la primera parada al tiempo de inicio
  if (updatedStops.length > 0) {
    updatedStops[0].stop_time = `${startTimeMoment.format(
      "hh:mm A"
    )} - ${startTimeMoment.add(1, "minute").format("hh:mm A")}`;
    currentTime = startTimeMoment.add(1, "minute"); // Actualizar currentTime para incluir los 2 minutos de parada
  }

  let stopIndex = 1; // Comenzar a asignar tiempos desde el índice 1 (la segunda parada)

  // Iterar sobre cada tramo (leg)
  directionsResult.routes[0].legs.forEach((leg) => {
    // Duración del tramo actual en minutos
    const durationInMinutes = leg.duration?.value
      ? Math.ceil(leg.duration.value / 60) // Convertir segundos a minutos y redondear hacia arriba
      : 0; // Por defecto 0 si está indefinido

    // Número de paradas en el tramo (excluyendo el origen)
    const waypointsCount = leg.via_waypoints.length + 1; // +1 para la dirección final

    for (let i = 0; i < waypointsCount; i++) {
      if (stopIndex >= updatedStops.length) break; // Prevenir desbordamiento de índices

      // Calcular la hora de llegada a la parada redondeando hacia arriba
      currentTime = currentTime.add(
        Math.ceil(durationInMinutes / waypointsCount),
        "minute"
      );

      // Establecer el rango de tiempo de la parada, incluyendo los 2 minutos de parada
      updatedStops[stopIndex].stop_time = `${currentTime.format(
        "hh:mm A"
      )} - ${currentTime.add(1, "minute").format("hh:mm A")}`;

      // Actualizar currentTime para reflejar el final de los 2 minutos de parada
      currentTime = currentTime.add(1, "minute");
      stopIndex++;
    }
  });

  return updatedStops;
};

export const ExportRoute = ({ route, driver }: Props) => {
  const organization = localStorage.getItem("organization");

  const { data: response } = useList<IOrganization>({
    resource: "organizations",
  });

  const { data } = useList<IStudent>({
    resource: "students",
    filters: [
      {
        field: "organization_id",
        operator: "eq",
        value: organization,
      },
    ],
    pagination: {
      mode: "off",
    },
  });
  const students = data?.data;

  const [organizationData, setOrganizationData] = useState<
    IOrganization | undefined
  >();
  const [processedStops, setProcessedStops] = useState<ProcessedStops | null>(
    null
  );
  const directionsServiceRef = useRef<google.maps.DirectionsService | null>(
    null
  );
  const [stops, setStops] = useState<IStop[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const organizationId = localStorage.getItem("organization");
    const selectedOrganization: IOrganization | undefined = response?.data.find(
      (option) => option.id.toString() === organizationId
    );
    setOrganizationData(selectedOrganization);
  }, [response]);

  const triggerExport = async () => {
    setIsLoading(true);
    if (!route) return;

    const stops = route.stop_list;
    if (!stops) return;

    const processed = processStops(stops);
    setProcessedStops(processed);

    directionsServiceRef.current = new google.maps.DirectionsService();

    if (!processed) return;

    const directionsRequest = {
      origin: processed.origin,
      destination: processed.destination,
      waypoints: processed.waypoints,
      travelMode: google.maps.TravelMode.DRIVING,
    };

    try {
      const directionsResult = await new Promise<google.maps.DirectionsResult>(
        (resolve, reject) => {
          directionsServiceRef.current?.route(
            directionsRequest,
            (result, status) => {
              if (status === google.maps.DirectionsStatus.OK) {
                resolve(result as google.maps.DirectionsResult);
              } else {
                reject(status);
              }
            }
          );
        }
      );

      // Calculate stop times based on directions result
      const updatedStops = calculateStopTimes(
        route.start_time,
        directionsResult,
        stops
      );
      setStops(updatedStops);

      generatePDF(directionsResult);
    } catch (error) {
      console.error("Failed to fetch directions:", error);
    }
  };

  const generatePDF = async (
    directionsResult: google.maps.DirectionsResult
  ) => {
    const doc = new jsPDF();

    // Add logo
    const img = new Image();
    img.src = IMAGE_PATHS.logo;
    img.onload = async () => {
      doc.addImage(img, "PNG", 10, 10, 50, 20);

      const startY = 30;
      doc.setFontSize(12);

      // Prepare route data with optional fields
      const routeData = [
        ["Route Name", safeValue(route?.name)],
        ["Description", safeValue(route?.description)],
        ["Organization Name", safeValue(organizationData?.organization_name)],
        ["Contact Name", safeValue(organizationData?.contact_name)],
        ["Contact Name", safeValue(organizationData?.contact_number)],
        ["Driver", safeValue(driver)],
        ["Type of Route", safeValue(route?.route_type)],
        ["Total distance", safeValue(route?.distance) + " miles"],
        ["Type of Vehicle", safeValue(route?.vehicle_type)],
        ["Start Location", safeValue(route?.stop_list[0]?.address)],
        [
          "End Location",
          safeValue(route?.stop_list[route?.stop_list.length - 1]?.address),
        ],
        ["First Pick-up Time", safeValue(route?.start_time)],
        ["Last Drop-off Time", safeValue(stops[stops.length - 1]?.stop_time)],
      ].filter(([_, value]) => value !== "");

      autoTable(doc, {
        startY: startY + 10,
        head: [["General Information", ""]],
        body: routeData,
      });

      // Add static map image for overview polyline
      const overviewMapUrl = generateStaticMapUrl(
        directionsResult.routes[0].overview_polyline,
        stops
      );
      await addImageToPdf(
        doc,
        overviewMapUrl,
        10,
        (doc as any).lastAutoTable.finalY + 20,
        180,
        120
      );

      doc.addPage();

      const addStopsTable = (
        startY: number,
        studentsList: IStudent[] | undefined
      ) => {
        if (route?.stop_list) {
          const getStudentInfo = (studentId: any) => {
            return (
              studentsList?.find((student) => student.id === studentId) || {
                name: "",
                grade: "",
                contact_name: "",
                contact_number: "",
              }
            );
          };

          const stopsData = route.stop_list.flatMap(
            (stop: IStop, index: any) => {
              const stopRow = [
                stop.address,
                stop.stop_time === "Invalid Date" ? null : stop.stop_time,
                "",
                "",
                stop.notes,
              ];

              const pickUpStudentsRows = stop.pick_up_students?.map(
                (studentId: any, subIndex: number) => {
                  const studentInfo = getStudentInfo(studentId);
                  return [
                    "",
                    "",
                    `Pick-up: ${studentInfo.name}`,
                    studentInfo.grade || "",
                    "",
                    studentInfo.contact_name,
                    studentInfo.contact_number,
                  ];
                }
              ) || [["", "", "No pick up students"]];

              const dropOffStudentsRows = stop.drop_off_students?.map(
                (studentId: any, subIndex: number) => {
                  const studentInfo = getStudentInfo(studentId);
                  return [
                    "",
                    "",
                    `Drop-off: ${studentInfo.name}`,
                    studentInfo.grade || "",
                    "",
                    studentInfo.contact_name,
                    studentInfo.contact_number,
                  ];
                }
              ) || [["", "", "No drop off students"]];

              return [stopRow, ...pickUpStudentsRows, ...dropOffStudentsRows];
            }
          );

          autoTable(doc, {
            startY,
            head: [
              [
                "Address",
                "Stop Time",
                "Students",
                "Grade",
                "Stop Notes",
                "Contact Name",
                "Contact Number",
              ],
            ],
            styles: {
              fontSize: 8,
              cellPadding: 1.5,
            },
            body: stopsData,
          });
        }
      };

      addStopsTable(10, students);

      // Turn by turn directions
      for (let i = 0; i < directionsResult.routes[0].legs.length; i++) {
        const leg = directionsResult.routes[0].legs[i];
        doc.addPage();

        const legData = [
          route?.stop_list[i + 1].name
            ? `Stop: ${route?.stop_list[i + 1].name}`
            : "",
          `Address: ${safeValue(leg.end_address)}`,
        ];

        legData.forEach((line, index) => {
          doc.text(line, 20, 20 + index * 10);
        });

        const stepsData = leg.steps.map((step, stepIndex) => [
          `Step ${stepIndex + 1}`,
          safeValue(
            step.instructions
              .replace(/<[^>]+>/g, " ")
              .replace(/\s+/g, " ")
              .trim()
          ),
          safeValue(step.distance?.text),
          safeValue(step.duration?.text),
        ]);

        autoTable(doc, {
          startY: 40,
          head: [["Step", "Instructions", "Distance", "Duration"]],
          body: stepsData,
        });

        const legMapUrl = generateStaticMapUrlForLeg(leg);
        await addImageToPdf(
          doc,
          legMapUrl,
          10,
          (doc as any).lastAutoTable.finalY + 10,
          120,
          80
        );
      }
      setIsLoading(false);
      doc.save(`route_${route?.id}.pdf`);
    };
  };

  const generateStaticMapUrl = (polyline: any, stops: IStop[]) => {
    const apiKey = import.meta.env.VITE_APP_MAP_ID ?? "";
    const path = `enc:${polyline}`;
    const size = "600x400";
    const markers = route?.stop_list
      .map(
        (stop: IStop, index: number) =>
          `markers=label:${index + 1}%7C${encodeURIComponent(stop.address)}`
      )
      .join("&");
    return `https://maps.googleapis.com/maps/api/staticmap?size=${size}&scale=2&path=${path}&${markers}&maptype=hybrid&key=${apiKey}`;
  };

  const generateStaticMapUrlForLeg = (leg: google.maps.DirectionsLeg) => {
    const apiKey = import.meta.env.VITE_APP_MAP_ID ?? "";
    const size = "600x400";
    const markers = `markers=${encodeURIComponent(leg.end_address)}`;
    return `https://maps.googleapis.com/maps/api/staticmap?size=${size}&${markers}&scale=2&zoom=19&maptype=hybrid&key=${apiKey}`;
  };

  const addImageToPdf = (
    doc: jsPDF,
    imageUrl: string,
    x: number,
    y: number,
    width: number,
    height: number
  ) => {
    return new Promise<void>((resolve) => {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.src = imageUrl;
      img.onload = () => {
        doc.addImage(img, "PNG", x, y, width, height);
        resolve();
      };
    });
  };

  return (
    <ExportButton type="dashed" onClick={triggerExport} loading={isLoading} />
  );
};
