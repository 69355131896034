import { useState } from "react";
import { UseFormProps, useForm } from "@refinedev/antd";
import { IRoute } from "../../../interfaces";
import { Dayjs } from "dayjs";

type Props = {
  action: UseFormProps["action"];
};

export const useRouteForm = (props: Props) => {
  const [isFormDisabled, setIsFormDisabled] = useState(() =>
    props.action === "edit" ? true : false,
  );

  const form = useForm<IRoute>({
    action: props.action,
    redirect: false,
    onMutationSuccess: () => {
      setIsFormDisabled(true);
    },
  });
  const route = form.queryResult?.data?.data;


  const handleSetIsFormDisabled = (value: boolean) => {
    form.formProps.form?.resetFields();
    setIsFormDisabled(value);
  };

  const isLoading = form.queryResult?.isFetching || form.formLoading;

  return {
    ...form,
    route,
    formLoading: isLoading,
    isFormDisabled,
    setIsFormDisabled: handleSetIsFormDisabled,
    handleChange: (time: string | string[]) => form.formProps.form?.setFieldValue('start_time', time),
  };
};
