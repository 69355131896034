import { useEffect, useRef } from "react";
import { UseFormProps, useNavigation, useTranslate } from "@refinedev/core";
import {
  DeleteButton,
  ListButton,
  SaveButton,
  UseFormReturnType,
} from "@refinedev/antd";
import {
  Form,
  Input,
  InputProps,
  Segmented,
  Card,
  Flex,
  Divider,
  InputNumber,
  Button,
  InputRef,
  Select,
} from "antd";
import _debounce from "lodash/debounce";
import { IStop } from "../../../interfaces";
import {
  EditOutlined,
  EnvironmentOutlined,
  FileTextOutlined,
  RightCircleOutlined,
  SettingOutlined,
  SlidersOutlined,
} from "@ant-design/icons";
import { FormItemHorizontal } from "../../form";
import { StopStatus } from "../status";

type Props = {
  formProps: UseFormReturnType<IStop>["formProps"];
  saveButtonProps: UseFormReturnType<IStop>["saveButtonProps"];
  action: UseFormProps["action"];
  isFormDisabled: boolean;
  setIsFormDisabled: (value: boolean) => void;
  handleAddressChange: (address: string) => void;
};

export const StopFormFields = ({
  formProps,
  saveButtonProps,
  action,
  isFormDisabled,
  setIsFormDisabled,
  handleAddressChange,
}: Props) => {
  const titleInputRef = useRef<InputRef>(null);

  const t = useTranslate();
  const { list } = useNavigation();

  useEffect(() => {
    if (!isFormDisabled) {
      titleInputRef.current?.focus();
    }
  }, [isFormDisabled]);

  const statusField = Form.useWatch("isActive", formProps.form);
  const organization = localStorage.getItem("organization");

  return (
    <Form {...formProps} layout="horizontal" disabled={isFormDisabled}>
      <Card
        styles={{
          body: {
            padding: 0,
          },
        }}
      >
        <FormItemHorizontal
          icon={<SettingOutlined />}
          label={t("stops.fields.name.label")}
          name="name"
        >
          <Input placeholder={t("stops.fields.name.placeholder")} />
        </FormItemHorizontal>
        <Divider
          style={{
            margin: 0,
          }}
        />
        <FormItemHorizontal
          name="isActive"
          initialValue={true}
          icon={<RightCircleOutlined />}
          label={t("stops.fields.isActive.label")}
        >
          {isFormDisabled ? (
            <StopStatus value={statusField} />
          ) : (
            <Segmented
              options={[
                {
                  label: t("stops.fields.isActive.true"),
                  value: true,
                },
                {
                  label: t("stops.fields.isActive.false"),
                  value: false,
                },
              ]}
            />
          )}
        </FormItemHorizontal>
        <Divider
          style={{
            margin: 0,
          }}
        />
        <FormItemHorizontal
          name={["address"]}
          icon={<EnvironmentOutlined />}
          label={t("stops.fields.address")}
          flexProps={{
            align: "flex-start",
          }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            onChange={(e) => {
              handleAddressChange(e.target.value);
            }}
          />
        </FormItemHorizontal>
        <Divider
          style={{
            margin: 0,
          }}
        />
        <FormItemHorizontal
          icon={<SlidersOutlined />}
          label={t("stops.fields.stop_type")}
          name="type"
          initialValue={"home_address"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select>
            <Select.Option value="home_address">Home Address</Select.Option>
            <Select.Option value="school">School</Select.Option>
            <Select.Option value="stop">Stop</Select.Option>
            <Select.Option value="intersection">Intersection</Select.Option>
          </Select>
        </FormItemHorizontal>
        <Divider
          style={{
            margin: 0,
          }}
        />
        <FormItemHorizontal
          name={["notes"]}
          icon={<FileTextOutlined />}
          label={t("stops.fields.notes")}
          flexProps={{
            align: "flex-start",
          }}
        >
          <Input.TextArea rows={2} />
        </FormItemHorizontal>
        {organization ? (
          <Form.Item
            name={"organization_id"}
            initialValue={organization}
            style={{
              display: "none",
            }}
          >
            <Input value={organization} defaultValue={organization} />
          </Form.Item>
        ) : null}
        <Divider
          style={{
            margin: 0,
          }}
        />
      </Card>
      <Flex
        align="center"
        justify="space-between"
        style={{
          padding: "16px 16px 0px 16px",
        }}
      >
        {action === "create" && (
          <>
            <ListButton icon={false}>{t("actions.cancel")}</ListButton>
            <SaveButton
              {...saveButtonProps}
              style={{
                marginLeft: "auto",
              }}
              htmlType="submit"
              type="primary"
              icon={null}
            >
              Save
            </SaveButton>
          </>
        )}
        {action === "edit" &&
          (isFormDisabled ? (
            <>
              <DeleteButton
                type="text"
                onSuccess={() => {
                  list("stops");
                }}
                style={{
                  marginLeft: "-16px",
                }}
              />
              <Button
                style={{
                  marginLeft: "auto",
                }}
                disabled={false}
                icon={<EditOutlined />}
                onClick={() => setIsFormDisabled(false)}
              >
                {t("actions.edit")}
              </Button>
            </>
          ) : (
            <>
              <Button onClick={() => setIsFormDisabled(true)}>
                {t("actions.cancel")}
              </Button>
              <SaveButton
                {...saveButtonProps}
                style={{
                  marginLeft: "auto",
                }}
                htmlType="submit"
                type="primary"
                icon={null}
              >
                Save
              </SaveButton>
            </>
          ))}
      </Flex>

      {/* this is a workaround for registering fields to ant design form*/}
      {/* otherwise these fields will be null */}
      <Flex
        vertical
        style={{
          display: "none",
        }}
      >
        <Form.Item
          name={"geo_location"}
          style={{
            display: "none",
          }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber
            style={{
              width: "100%",
            }}
            addonBefore="Lat"
          />
        </Form.Item>
        <Form.Item
          style={{
            display: "none",
          }}
          name={"geo_location"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber
            addonBefore="Lng"
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
      </Flex>
    </Form>
  );
};
