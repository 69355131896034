import { useEffect, useState } from "react";
import { UseFormProps, useForm } from "@refinedev/antd";
import { useDebounceValue } from "usehooks-ts";
import {
  convertLatLng,
  LatLng,
  getAddressWithLatLng,
  getLatLngWithAddress,
} from "../../../utils";
import { IStop } from "../../../interfaces";

type Props = {
  action: UseFormProps["action"];
};

export const useStopForm = (props: Props) => {
  const [isFormDisabled, setIsFormDisabled] = useState(() =>
    props.action === "edit" ? true : false
  );

  const form = useForm<IStop>({
    action: props.action,
    redirect: false,
    onMutationSuccess: () => {
      setIsFormDisabled(true);
    },
  });
  const stop = form.queryResult?.data?.data;

  const [latLng, setLatLng] = useState<Partial<LatLng>>({
    lat: props.action === "create" ? 42.9552965 : undefined,
    lng: props.action === "create" ? -85.5780721 : undefined,
  });

  useEffect(() => {
    if (stop?.latitude) {
      setLatLng({
        lat: stop?.latitude,
        lng: stop?.longitude,
      });
    }
  }, [stop?.latitude, stop?.longitude]);

  // we are using these debounced values to get lang and lat from the address text
  // to minimize the number of requests, we are using debounced values
  const [debouncedAdressValue, setDebouncedAdressValue] = useDebounceValue(
    form.formProps.form?.getFieldValue(["address"]),
    500
  );

  // get lat and lng with address
  useEffect(() => {
    if (debouncedAdressValue) {
      getLatLngWithAddress(debouncedAdressValue).then((data) => {
        // set form field with lat and lng values
        if (data) {
          const { lat, lng } = convertLatLng({
            lat: data.lat,
            lng: data.lng,
          });

          // form.formProps.form?.setFieldValue("latitude", lat);
          // form.formProps.form?.setFieldValue("longitude", lng);
          form.formProps.form?.setFieldValue(
            "geo_location",
            `SRID=4326;POINT(${lat} ${lng})`
          );

          setLatLng({
            lat,
            lng,
          });
        }
      });
    }
  }, [debouncedAdressValue, form.formProps.form?.setFieldValue]);

  const handleMapOnDragEnd = async ({
    lat,
    lng,
  }: {
    lat: number;
    lng: number;
  }) => {
    // get address with lat lng and set form field
    const data = await getAddressWithLatLng({ lat, lng });
    if (data) {
      // set form field with address value
      form.formProps.form?.setFieldValue(["address"], data.address);
      form.formProps.form?.setFieldValue(
        "geo_location",
        `SRID=4326;POINT(${lat} ${lng})`
      );
      // if (props.action === "create") {
      //   setDebouncedAdressValue(data.address);
      // }
    }
  };

  const handleSetIsFormDisabled = (value: boolean) => {
    form.formProps.form?.resetFields();
    setIsFormDisabled(value);
  };

  const isLoading = form.queryResult?.isFetching || form.formLoading;

  return {
    ...form,
    stop,
    formLoading: isLoading,
    latLng,
    isFormDisabled,
    setIsFormDisabled: handleSetIsFormDisabled,
    handleAddressChange: (address: string) => setDebouncedAdressValue(address),
    handleMapOnDragEnd,
  };
};
