import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Card } from "antd";

export default function Calendar() {
  return (
    <Card>
    <FullCalendar
      plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
      initialView={"dayGridMonth"}
      headerToolbar={false}
      timeZone="UTC"
      height={600}
    />
    </Card>
  )
}