import {
  useGo,
  useNavigation,
  useOne,
  useShow,
  useTranslate,
} from "@refinedev/core";
import {
  DateField,
  DeleteButton,
  ListButton,
  NumberField,
  SaveButton,
  useForm,
  useSelect,
} from "@refinedev/antd";
import {
  Flex,
  Divider,
  Card,
  Typography,
  Select,
  Form,
  Col,
  Row,
  Spin,
  Tag,
  TimePicker,
  Input,
} from "antd";
import { LeftOutlined, SaveOutlined } from "@ant-design/icons";
import _debounce from "lodash/debounce";
import { ExportRoute, RouteStops, StopsListCard } from "../../components";
import { IDriver, IRoute, IStop, IVehicle } from "../../interfaces";
import { useEffect, useState, useRef } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import RouteIcon from "@mui/icons-material/Route";
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

export const RoutesEdit = () => {
  const t = useTranslate();
  const go = useGo();
  const { listUrl } = useNavigation();
  const { queryResult } = useShow<IRoute>();
  const route = queryResult?.data?.data;
  const { formProps, formLoading, saveButtonProps } = useForm<IRoute>({
    redirect: false,
  });
  const [time, setTime] = useState<Dayjs>(dayjs(route?.start_time, "HH:mm"));
  const organization = localStorage.getItem("organization");

  const onChange = (time: any, timeString: string | string[]) => {
    formProps.form?.setFieldValue("start_time", timeString);
  };

  const { data: vehicleData } = useOne<IVehicle>({
    resource: "vehicles",
    id: route?.vehicle,
  });

  const { selectProps: driverSelectProps } = useSelect<IDriver>({
    resource: "drivers",
    defaultValue: route?.driver,
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "organization_id",
        operator: "eq",
        value: organization,
      },
    ],
    queryOptions: {
      enabled: !!route?.driver,
    },
  });

  const { selectProps: vehicleSelectProps } = useSelect<IVehicle>({
    resource: "vehicles",
    ...(route?.vehicle && { defaultValue: route?.vehicle }),
    optionLabel: "vehicle_model",
    optionValue: "id",
    filters: [
      {
        field: "organization_id",
        operator: "eq",
        value: organization,
      },
    ],
    ...(route?.vehicle && { queryOptions: { enabled: !!route?.vehicle } }),
  });

  const [stops, setStops] = useState<IStop[]>([]);
  const [driverName, setDriverName] = useState<any>();
  const routeStopsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (route?.stop_list) {
      setStops(route.stop_list);
    }
  }, [route]);

  const handleUpdateStops = (updatedStops: IStop[]) => {
    setStops(updatedStops);
  };

  useEffect(() => {
    if (formProps.form) {
      formProps.form.setFieldValue("stop_list", stops);
    }
  }, [stops, formProps.form]);

  useEffect(() => {
    setTimeout(() => {
      formProps?.form?.setFieldValue(
        "distance",
        localStorage.getItem("distance")
      );
    }, 1000);
  }, [localStorage.getItem("distance")]);

  useEffect(() => {
    setTimeout(() => {
      formProps?.form?.setFieldValue(
        "duration",
        localStorage.getItem("duration")
      );
    }, 1000);
  }, [localStorage.getItem("duration")]);

  useEffect(() => {
    if (route?.driver && driverSelectProps.options) {
      const selectedDriver = driverSelectProps.options.find(
        (option) => option.value === route.driver
      );
      setDriverName(selectedDriver?.label);
    }
  }, [route?.driver, driverSelectProps.options]);

  useEffect(() => {
    const defaultTime = dayjs("14:30", "HH:mm");
    setTime(defaultTime);
  }, [route?.start_time]);

  return (
    <Spin spinning={formLoading}>
      <Form {...formProps}>
        <Flex justify="space-between" style={{ marginBottom: 10 }}>
          <ListButton icon={<LeftOutlined />}>{t("routes.title")}</ListButton>
        </Flex>
        <Card
          styles={{
            body: {
              padding: 16,
            },
            actions: {
              padding: "0 10px",
            },
          }}
          actions={[
            <Flex justify="space-around">
              <Flex gap="small" style={{ flexWrap: "wrap" }}>
                {route?.weekdays &&
                  route?.weekdays.map((day: string) => {
                    const capitalizeFirstLetter = (str: string) =>
                      str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
                    return (
                      <Tag
                        bordered={false}
                        color="processing"
                        style={{ height: "fit-content" }}
                        key={day}
                      >
                        {capitalizeFirstLetter(day)}
                      </Tag>
                    );
                  })}
              </Flex>
              {!route?.weekdays ? (
                <Tag
                  bordered={false}
                  color="processing"
                  style={{ height: "fit-content" }}
                >
                  <DateField
                    style={{ color: "#1677ff", fontSize: "12px" }}
                    value={dayjs(route?.start_date)}
                    format="LL"
                  />
                </Tag>
              ) : null}
              <Typography style={{ display: "flex", gap: "5px" }}>
                <AccessTimeIcon sx={{ color: "#1677ff" }} fontSize="small" />
                {route?.duration ? (
                  <NumberField
                    value={route?.duration || 0}
                    options={{
                      style: "unit",
                      unit: "minute",
                    }}
                  />
                ) : (
                  "N/A"
                )}
              </Typography>
              <Typography style={{ display: "flex", gap: "5px" }}>
                <RouteIcon sx={{ color: "#1677ff" }} fontSize="small" />
                <NumberField
                  value={route?.distance || 0}
                  options={{
                    style: "unit",
                    unit: "mile",
                  }}
                />
              </Typography>
            </Flex>,
          ]}
        >
          <Card.Meta
            title={
              <Flex justify="space-between">
                <Form.Item
                  name={"name"}
                  initialValue
                  style={{ marginBottom: 0 }}
                >
                  <Input />
                </Form.Item>
                <Flex key="actions" justify="end" gap={4}>
                  <Form.Item
                    name={"sports"}
                    style={{ marginBottom: 0 }}
                    initialValue={true}
                  >
                    <Select>
                      <Select.Option value={true}>Sports</Select.Option>
                      <Select.Option value={false}>Default</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name={"vehicle_type"}
                    style={{ marginBottom: 0 }}
                    initialValue={true}
                  >
                    <Select>
                      <Select.Option value="car">Car</Select.Option>
                      <Select.Option value="van">Van</Select.Option>
                      <Select.Option value="bus">Bus</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name={"isActive"}
                    style={{ marginBottom: 0 }}
                    initialValue={true}
                  >
                    <Select>
                      <Select.Option value={true}>Active</Select.Option>
                      <Select.Option value={false}>Inactive</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name={"driver"}
                    style={{ marginBottom: 0 }}
                    initialValue={true}
                  >
                    <Select {...driverSelectProps} />
                  </Form.Item>
                  <Form.Item
                    name={"vehicle"}
                    style={{ marginBottom: 0, width: 100 }}
                    initialValue={true}
                  >
                    <Select {...vehicleSelectProps} />
                  </Form.Item>
                  {route ? (
                    <Form.Item>
                      <TimePicker
                        use12Hours
                        format="hh:mm A"
                        onChange={onChange}
                        defaultValue={
                          route.start_time
                            ? dayjs(route?.start_time, "hh:mm A")
                            : null
                        }
                      />
                    </Form.Item>
                  ) : null}
                  <Form.Item
                    style={{ display: "none" }}
                    name="start_time"
                  ></Form.Item>
                  <Form.Item
                    name={"stop_list"}
                    style={{ display: "none" }}
                  ></Form.Item>
                  <Form.Item
                    name={"distance"}
                    style={{ display: "none" }}
                    initialValue={Number(localStorage.getItem("distance"))}
                  >
                    <Input
                      value={Number(localStorage.getItem("distance")) || 0}
                      defaultValue={
                        Number(localStorage.getItem("distance")) || 0
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name={"duration"}
                    style={{ display: "none" }}
                    initialValue={Number(localStorage.getItem("duration"))}
                  >
                    <Input
                      value={Number(localStorage.getItem("duration")) || 0}
                      defaultValue={
                        Number(localStorage.getItem("duration")) || 0
                      }
                    />
                  </Form.Item>
                  <ExportRoute route={route} driver={driverName} />
                  <SaveButton icon={<SaveOutlined />} {...saveButtonProps}>
                    {t("buttons.save")}
                  </SaveButton>
                  <DeleteButton
                    onSuccess={() => {
                      return go({
                        to: `${listUrl("routes")}`,
                      });
                    }}
                  />
                </Flex>
              </Flex>
            }
          />
        </Card>
        <Divider />
        <Row gutter={16} wrap>
          <Col xs={24} md={12} lg={10}>
            <StopsListCard
              stops={stops}
              route={route}
              totalCapacity={vehicleData?.data.passenger_capacity}
              onUpdateStops={handleUpdateStops}
            />
          </Col>
          <Col
            xs={24}
            md={12}
            lg={14}
            style={{
              height: "650px",
            }}
            ref={routeStopsRef}
          >
            <RouteStops stops={stops} />
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};
