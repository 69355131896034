import React, { useEffect, useState, useCallback } from "react";
import { BaseOption, useList, useTranslate } from "@refinedev/core";
import { EditButton, useSelect } from "@refinedev/antd";
import { IRoute, IStop, IStudent } from "../../../interfaces";
import {
  Button,
  Card,
  Flex,
  Form,
  List,
  Select,
  Space,
  Typography,
  message,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { LabeledValue } from "antd/es/select";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const ItemType = "STOP_ITEM";

interface DragItemProps {
  item: IStop;
  index: number;
  moveItem: (fromIndex: number, toIndex: number) => void;
  removeStop: (id: number) => void;
  onChangePickupStudents: (value: BaseOption, id: number) => void;
  onChangeDropoffStudents: (value: BaseOption, id: number) => void;
  remainingCapacity: number;
}

interface StudentsProps {
  totalStudents: any;
  students: any;
}

const PickUpStudents: React.FC<StudentsProps> = ({
  totalStudents,
  students,
}) => {
  if (!students || !students.length) {
    return null;
  }
  const filteredStudents = totalStudents.filter((student: { value: any }) =>
    students.some((s: any) => s === student.value)
  );
  const studentNames = filteredStudents
    .map((student: { label: any; value: any }) => student.label)
    .join(" - ");

  return (
    <Typography
      style={{
        gap: "5px",
        alignItems: "center",
        fontSize: 12,
      }}
    >
      <span style={{ fontWeight: "bold" }}>Pick-up Students:</span>{" "}
      {studentNames}
    </Typography>
  );
};

const DropOffStudents: React.FC<StudentsProps> = ({
  totalStudents,
  students,
}) => {
  if (!students || !students.length) {
    return null;
  }
  const filteredStudents = totalStudents.filter((student: { value: any }) =>
    students.some((s: any) => s === student.value)
  );
  const studentNames = filteredStudents
    .map((student: { label: any; value: any }) => student.label)
    .join(" - ");

  return (
    <Typography
      style={{
        gap: "5px",
        alignItems: "center",
        fontSize: 12,
      }}
    >
      <span style={{ fontWeight: "bold" }}>Drop-off Students:</span>{" "}
      {studentNames}
    </Typography>
  );
};

const DragItem: React.FC<DragItemProps> = ({
  item,
  index,
  moveItem,
  removeStop,
  onChangePickupStudents,
  onChangeDropoffStudents,
  remainingCapacity,
}) => {
  const [, ref] = useDrag({
    type: ItemType,
    item: { index },
  });
  const organization = localStorage.getItem("organization");

  const { selectProps: studentsSelectProps } = useSelect<IStudent>({
    resource: "students",
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "organization_id",
        operator: "eq",
        value: organization,
      },
    ],
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem: { index: number }) => {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <div ref={(node) => ref(drop(node))}>
      <Card
        hoverable
        size="small"
        bordered={false}
        style={{ padding: 5 }}
        title={
          <Typography.Title level={5} style={{ marginTop: 10 }}>
            {item.address}
          </Typography.Title>
        }
        extra={
          <div
            key="actions"
            style={{
              display: "flex",
              justifyContent: "end",
              gap: "4px",
              padding: "0 10px",
            }}
          >
            <EditButton
              style={{
                width: "100%",
                padding: 5,
              }}
              hideText
              size="small"
              icon={<EditOutlined />}
              recordItemId={item.id}
              resource="stops"
            />
            <Button
              size="small"
              style={{ width: "100%", padding: 5 }}
              danger
              icon={<DeleteOutlined />}
              onClick={() => removeStop(item.id)}
            />
          </div>
        }
        className="draggable-item"
      >
        <Space style={{ width: '100%', gap: '2px' }} direction="vertical">
          <Typography.Text style={{ fontSize: '12px', fontWeight: 'bold', marginBottom: '0px'}}>Pick-up Students</Typography.Text>
            <Select
              mode="multiple"
              size="small"
              style={{ width: "100%", marginBottom: '5px' }}
              placeholder="Pick-up Students"
              // maxCount={remainingCapacity}
              defaultValue={item.pick_up_students}
              onChange={(value) => {
                onChangePickupStudents(value, item.id);
              }}
              {...studentsSelectProps}
            />
          <Typography.Text style={{ fontSize: '12px', fontWeight: 'bold', marginBottom: '0px'}}>Drop-off Students</Typography.Text>
            <Select
              mode="multiple"
              size="small"
              style={{ width: "100%" }}
              placeholder="Drop-off Students"
              defaultValue={item.drop_off_students}
              onChange={(value) => {
                onChangeDropoffStudents(value, item.id);
              }}
              {...studentsSelectProps}
            />
        </Space>
      </Card>
    </div>
  );
};

interface DragListProps {
  items: IStop[];
  moveItem: (fromIndex: number, toIndex: number) => void;
  removeStop: (id: number) => void;
  onChangePickupStudents: (value: BaseOption, id: number) => void;
  onChangeDropoffStudents: (value: BaseOption, id: number) => void;
  remainingCapacity: number;
}

const DragList: React.FC<DragListProps> = ({
  items,
  moveItem,
  removeStop,
  onChangePickupStudents,
  onChangeDropoffStudents,
  remainingCapacity,
}) => {
  return (
    <List
      grid={{
        gutter: [16, 16],
        column: 1,
      }}
    >
      {items.map((item, index) => (
        <List.Item key={item.id}>
          <DragItem
            item={item}
            index={index}
            moveItem={moveItem}
            removeStop={removeStop}
            onChangePickupStudents={onChangePickupStudents}
            onChangeDropoffStudents={onChangeDropoffStudents}
            remainingCapacity={remainingCapacity}
          />
        </List.Item>
      ))}
    </List>
  );
};

type Props = {
  stops: IStop[];
  route: IRoute | undefined;
  totalCapacity: number;
  onUpdateStops: (updatedStops: IStop[]) => void;
};

const debounce = (func: (...args: any[]) => void, wait: number) => {
  let timeout: NodeJS.Timeout;
  return function (...args: any[]) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

export const StopsListCard: React.FC<Props> = (props) => {
  const [data, setData] = useState<IStop[]>([]);
  const t = useTranslate();
  const [messageApi, contextHolder] = message.useMessage();
  const organization = localStorage.getItem("organization");
  const [remainingCapacity, setRemainingCapacity] = useState<number>(
    props.totalCapacity
  );

  const moveItem = useCallback(
    (fromIndex: number, toIndex: number) => {
      const updatedData = [...data];
      const [movedItem] = updatedData.splice(fromIndex, 1);
      updatedData.splice(toIndex, 0, movedItem);
      setData(updatedData);
      props.onUpdateStops(updatedData);
    },
    [data, props]
  );

  const calculateInitialCapacity = (stops: IStop[]) => {
    let totalPickUpStudents = 0;
    let totalDropOffStudents = 0;

    stops.forEach((stop) => {
      totalPickUpStudents += stop.pick_up_students
        ? stop.pick_up_students.length
        : 0;
      totalDropOffStudents += stop.drop_off_students
        ? stop.drop_off_students.length
        : 0;
    });

    // Calculate the remaining capacity by subtracting pick-up students and adding drop-off students
    const totalStudents = totalPickUpStudents - totalDropOffStudents;
    return props.totalCapacity - totalStudents;
  };

  const debouncedMoveItem = useCallback(debounce(moveItem, 300), [moveItem]);

  const { selectProps: stopsSelectProps } = useSelect<IStop>({
    resource: "stops",
    optionLabel: "address",
    filters: [
      {
        field: "organization_id",
        operator: "eq",
        value: organization,
      },
    ],
  });

  const { data: stopsData } = useList<IStop>({
    resource: "stops",
    pagination: {
      mode: "off",
    },
    filters: [
      {
        field: "organization_id",
        operator: "eq",
        value: organization,
      },
    ],
  });

  const updateCapacity = (
    addedStudents: BaseOption[],
    removedStudents: BaseOption[],
    action: "pickup" | "dropoff"
  ) => {
    const addedCount = addedStudents.length;
    const removedCount = removedStudents.length;

    // For pickup, increase the count. For dropoff, decrease the count.
    if (action === "pickup") {
      setRemainingCapacity((prev) => prev - addedCount + removedCount);
    } else if (action === "dropoff") {
      setRemainingCapacity((prev) => prev + addedCount - removedCount);
    }
  };

  const onChangePickupStudents = (value: any, id: number) => {
    const stop = data.find((s) => s.id === id);
    if (stop) {
      const updatedStops = data.map((s) =>
        s.id === id ? { ...s, pick_up_students: value } : s
      );
      setData(updatedStops);
      setRemainingCapacity(remainingCapacity + 1);
      props.onUpdateStops(updatedStops);
    }
  };

  const onChangeDropoffStudents = (value: any, id: number) => {
    const stop = data.find((s) => s.id === id);
    if (stop) {
      const previousDropoffStudents = stop.drop_off_students || [];
      updateCapacity(value, previousDropoffStudents, "dropoff");

      const updatedStops = data.map((s) =>
        s.id === id ? { ...s, drop_off_students: value } : s
      );
      setData(updatedStops);
      props.onUpdateStops(updatedStops);
    }
  };

  const removeStop = (id: number) => {
    const updatedStops = data.filter((item) => item.id !== id);
    setData(updatedStops);
    props.onUpdateStops(updatedStops);
  };

  const addStop = (value: string | number | LabeledValue) => {
    const selectedStop = stopsData?.data.find((option) => option.id === value);
    if (
      selectedStop &&
      !data.some((item) => item.id === selectedStop.id) &&
      !props.stops.some((stop) => stop.id === selectedStop.id)
    ) {
      const updatedStops = [...data, selectedStop];
      setData(updatedStops);
      props.onUpdateStops(updatedStops);
    } else {
      messageApi.open({
        type: "error",
        content: "Stop already added.",
      });
    }
  };

  useEffect(() => {
    setData(props.stops);
    const initialCapacity = calculateInitialCapacity(props.stops);
    setRemainingCapacity(initialCapacity);
  }, [props.stops, props.totalCapacity]);

  useEffect(() => {
    if(remainingCapacity <= 0) {
      messageApi.open({
        type: "warning",
        content: `You are exceeding the vehicle’s passenger capacity. It can only accommodate ${props.totalCapacity} passengers.`,
      });
    }
  }, [remainingCapacity]);

  return (
    <>
      {contextHolder}
      <DndProvider backend={HTML5Backend}>
        <div
          id="scrollableDiv"
          style={{
            display: "block",
            height: "650px",
            overflow: "auto",
          }}
        >
          {/* <Typography.Title level={5}>
            Remaining Capacity: {remainingCapacity}
          </Typography.Title> */}
          {data.length ? (
            <DragList
              items={data}
              moveItem={debouncedMoveItem}
              removeStop={removeStop}
              onChangePickupStudents={onChangePickupStudents}
              onChangeDropoffStudents={onChangeDropoffStudents}
              remainingCapacity={remainingCapacity}
            />
          ) : (
            <Card style={{ marginBottom: "10px" }} size="small">
              <Card.Meta
                title={t("stops.stops")}
                description={t("stops.empty")}
              />
            </Card>
          )}
          <Form.Item>
            <Select
              placeholder={t("actions.add")}
              allowClear
              onSelect={addStop}
              {...stopsSelectProps}
            />
          </Form.Item>
        </div>
      </DndProvider>
    </>
  );
};
