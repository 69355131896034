import { useTranslate, useGetToPath, useGo } from "@refinedev/core";
import {
  SaveButton,
  useStepsForm,
  useSelect,
  UseFormReturnType,
} from "@refinedev/antd";
import {
  Form,
  Select,
  Input,
  Button,
  Steps,
  Modal,
  Flex,
  theme,
  InputNumber,
} from "antd";
import { ISchool, IStudent } from "../../interfaces";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import InputMask from "react-input-mask";

export const StudentCreate = () => {
  const t = useTranslate();
  const getToPath = useGetToPath();
  const [searchParams] = useSearchParams();
  const go = useGo();
  const { token } = theme.useToken();

  const { current, gotoStep, stepsProps, formProps, saveButtonProps } =
    useStepsForm<IStudent>();

  const { formList } = useFormList({ formProps });

  const handleModalClose = () => {
    go({
      to:
        searchParams.get("to") ??
        getToPath({
          action: "list",
        }) ??
        "",
      query: {
        to: undefined,
      },
      options: {
        keepQuery: true,
      },
      type: "replace",
    });
  };

  const isLastStep = current === formList.length - 1;
  const isFirstStep = current === 0;

  return (
    <Modal
      open
      destroyOnClose
      maskClosable={false}
      title={t("students.actions.add")}
      styles={{
        header: {
          padding: "20px 24px",
          margin: 0,
          borderBottom: `1px solid ${token.colorBorderSecondary}`,
        },
        footer: {
          padding: "20px 24px",
          margin: 0,
          borderTop: `1px solid ${token.colorBorderSecondary}`,
        },
        content: {
          padding: 0,
        },
      }}
      footer={() => {
        return (
          <Flex align="center" justify="space-between">
            <Button onClick={handleModalClose}>{t("buttons.cancel")}</Button>
            <Flex align="center" gap={16}>
              <Button
                disabled={isFirstStep}
                onClick={() => {
                  gotoStep(current - 1);
                }}
              >
                {t("buttons.previousStep")}
              </Button>
              {isLastStep ? (
                <SaveButton icon={false} {...saveButtonProps} />
              ) : (
                <Button
                  type="primary"
                  onClick={() => {
                    gotoStep(current + 1);
                  }}
                >
                  {t("buttons.nextStep")}
                </Button>
              )}
            </Flex>
          </Flex>
        );
      }}
      onCancel={handleModalClose}
    >
      <Flex style={{ padding: "20px 24px" }}>
        <Steps {...stepsProps} responsive>
          <Steps.Step title={t("students.steps.personal")} />
          <Steps.Step title={t("students.steps.school")} />
          <Steps.Step title={t("students.steps.contact")} />
        </Steps>
      </Flex>
      <Form {...formProps} layout="vertical">
        {formList[current]}
      </Form>
    </Modal>
  );
};

type UseFormListProps = {
  formProps: UseFormReturnType<IStudent>["formProps"];
};

const useFormList = (props: UseFormListProps) => {
  const t = useTranslate();
  const organization = localStorage.getItem("organization");

  const { selectProps: schoolSelectProps } = useSelect<ISchool>({
    resource: "schools",
    optionLabel: "name",
    optionValue: "id",
    filters: [
      {
        field: "organization_id",
        operator: "eq",
        value: organization,
      },
    ],
  });

  const { selectProps: locationsSelectProps } = useSelect({
    resource: "stops",
    optionLabel: "address",
    optionValue: "id",
    filters: [
      {
        field: "organization_id",
        operator: "eq",
        value: organization,
      },
    ],
  });

  const formList = useMemo(() => {
    const step1 = (
      <Flex
        key="personal"
        vertical
        style={{
          padding: "20px 24px",
        }}
      >
        {organization ? (
          <Form.Item
            name={"organization_id"}
            initialValue={organization}
            style={{
              display: "none",
            }}
          >
            <Input value={organization} defaultValue={organization} />
          </Form.Item>
        ) : null}
        <Form.Item
          label={t("students.fields.name.label")}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("students.fields.address")}
          name="home_location"
          // rules={[
          //   {
          //     required: true,
          //   },
          // ]}
        >
           <Select {...locationsSelectProps} />
        </Form.Item>
      </Flex>
    );

    const step2 = (
      <Flex
        key="school"
        vertical
        style={{
          padding: "20px 24px",
        }}
      >
        <Form.Item
          label={t("students.fields.school")}
          name="school"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...schoolSelectProps} />
        </Form.Item>
        <Form.Item label={t("students.fields.grade")} name="grade">
          <InputNumber />
        </Form.Item>
      </Flex>
    );

    const step3 = (
      <Flex
        key="contact"
        vertical
        style={{
          padding: "20px 24px",
        }}
      >
        <Form.Item
          label={t("students.fields.contact_name")}
          name="contact_name"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("students.fields.contact_number")}
          name="contact_number"
        >
          <InputMask mask="(999) 999 99 99">
            {/* 
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore */}
            {(props: InputProps) => <Input {...props} />}
          </InputMask>
        </Form.Item>
        <Form.Item
          label={t("students.fields.notes")}
          name="notes"
        >
          <Input.TextArea rows={3} />
        </Form.Item>
      </Flex>
    );

    return [step1, step2, step3];
  }, [props.formProps]);

  return { formList };
};
