import { useTranslate, useGetToPath, useGo } from "@refinedev/core";
import {
  SaveButton,
  useStepsForm,
  useSelect,
  UseFormReturnType,
} from "@refinedev/antd";
import {
  Form,
  Select,
  Input,
  Button,
  Steps,
  Modal,
  Flex,
  theme,
  InputNumber,
  TimePicker,
} from "antd";
import { ILocation, ISchool, IStop } from "../../interfaces";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

export const SchoolCreate = () => {
  const t = useTranslate();
  const getToPath = useGetToPath();
  const [searchParams] = useSearchParams();
  const go = useGo();
  const { token } = theme.useToken();

  const { current, gotoStep, stepsProps, formProps, saveButtonProps } =
    useStepsForm<ISchool>();

  const { formList } = useFormList({
    formProps,
    handleChange: function (time: string | string[]): void {
      throw new Error("Function not implemented.");
    }
  });

  const handleModalClose = () => {
    go({
      to:
        searchParams.get("to") ??
        getToPath({
          action: "list",
        }) ??
        "",
      query: {
        to: undefined,
      },
      options: {
        keepQuery: true,
      },
      type: "replace",
    });
  };

  const isLastStep = current === formList.length - 1;
  const isFirstStep = current === 0;

  return (
    <Modal
      open
      destroyOnClose
      maskClosable={false}
      title={t("schools.actions.add")}
      styles={{
        header: {
          padding: "20px 24px",
          margin: 0,
          borderBottom: `1px solid ${token.colorBorderSecondary}`,
        },
        footer: {
          padding: "20px 24px",
          margin: 0,
          borderTop: `1px solid ${token.colorBorderSecondary}`,
        },
        content: {
          padding: 0,
        },
      }}
      footer={() => {
        return (
          <Flex align="center" justify="space-between">
            <Button onClick={handleModalClose}>{t("buttons.cancel")}</Button>
            <Flex align="center" gap={16}>
              <Button
                disabled={isFirstStep}
                onClick={() => {
                  gotoStep(current - 1);
                }}
              >
                {t("buttons.previousStep")}
              </Button>
              {isLastStep ? (
                <SaveButton icon={false} {...saveButtonProps} />
              ) : (
                <Button
                  type="primary"
                  onClick={() => {
                    gotoStep(current + 1);
                  }}
                >
                  {t("buttons.nextStep")}
                </Button>
              )}
            </Flex>
          </Flex>
        );
      }}
      onCancel={handleModalClose}
    >
      <Flex style={{ padding: "20px 24px" }}>
        <Steps {...stepsProps} responsive>
          <Steps.Step title={t("schools.steps.school")} />
          <Steps.Step title={t("schools.steps.details")} />
        </Steps>
      </Flex>
      <Form {...formProps} layout="vertical">
        {formList[current]}
      </Form>
    </Modal>
  );
};

type UseFormListProps = {
  formProps: UseFormReturnType<ISchool>["formProps"];
  handleChange: (time: string | string[]) => void;
};

const useFormList = (props: UseFormListProps) => {
  const t = useTranslate();
  const organization = localStorage.getItem("organization");

  const { selectProps: locationSelectProps } = useSelect<IStop>({
    resource: "stops",
    optionLabel: "address",
    optionValue: "id",
    filters: [
      {
        field: "organization_id",
        operator: "eq",
        value: organization,
      },
      {
        field: "type",
        operator: "eq",
        value: "school",
      },
    ],
  });

  const onChangeStartTime = (time: any, timeString: string | string[]) => {
    props.formProps.form?.setFieldValue('start_time', timeString);
  };
  const onChangeEndTime = (time: any, timeString: string | string[]) => {
    props.formProps.form?.setFieldValue('end_time', timeString);
  };

  const formList = useMemo(() => {
    const step1 = (
      <Flex
        key="personal"
        vertical
        style={{
          padding: "20px 24px",
        }}
      >
        {organization ? (
          <Form.Item
            name={"organization_id"}
            initialValue={organization}
            style={{
              display: "none",
            }}
          >
            <Input value={organization} defaultValue={organization} />
          </Form.Item>
        ) : null}
        <Form.Item
          label={t("schools.fields.name")}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("schools.fields.location")}
          name="location"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...locationSelectProps} />
        </Form.Item>
      </Flex>
    );

    const step2 = (
      <Flex
        key="school"
        vertical
        style={{
          padding: "20px 24px",
        }}
      >
        <Form.Item
          label={t("schools.fields.start_time")}
          // name="start_time"
        >
          <TimePicker use12Hours format="hh:mm A" onChange={onChangeStartTime} />
        </Form.Item>
        <Form.Item
          label={t("schools.fields.end_time")}
          // name="end_time"
        >
          <TimePicker use12Hours format="hh:mm A" onChange={onChangeEndTime} />
        </Form.Item>
      </Flex>
    );

    return [step1, step2];
  }, [props.formProps]);

  return { formList };
};
